import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ApartmentIcon from '@mui/icons-material/Apartment'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import ArticleIcon from '@mui/icons-material/Article'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import BusinessIcon from '@mui/icons-material/Business'
import CategoryIcon from '@mui/icons-material/Category'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import EngineeringIcon from '@mui/icons-material/Engineering'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import ListAltIcon from '@mui/icons-material/ListAlt'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PercentIcon from '@mui/icons-material/Percent'
import PinOutlinedIcon from '@mui/icons-material/PinOutlined'
import StraightenIcon from '@mui/icons-material/Straighten'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'

import {
	ContractIcon,
	CostInvoiceIcon,
	DebitNoteIcon,
	DemandIcon,
	GoodsReceivedNoteIcon,
	InvestorProcessingProtocolIcon,
	OfferIcon,
	OrderIcon,
	ProcessingProtocolIcon,
	ProjectIcon,
	SalesInvoiceIcon,
} from 'assets'

import useRoutePermission from './UseRoutePermission'

const sxStyles = {
	icon: {
		mr: '5px',
		fontSize: '20px',
	},
}

const useNavigationItems = () => {
	const { isPathAllowed, isAnyPathFromGroupAllowed } = useRoutePermission()

	const { t } = useTranslation('navigation')

	const allNavigationItems = useMemo(
		() => [
			{
				id: 'projects',
				icon: <ProjectIcon sx={{ ...sxStyles.icon, fontSize: '16px' }} />,
				text: t('Projects'),
				linkPath: '/projects',
				activationRoutes: [{ path: '/projects/*' }, { path: '/project/*' }],
				visible: isPathAllowed('/projects'),
			},
			{
				id: 'documents',
				icon: <ArticleIcon sx={sxStyles.icon} />,
				text: t('Documents'),
				visible: isAnyPathFromGroupAllowed([
					'/demands',
					'/offers',
					'/contracts',
					'/orders',
					'/processingProtocols',
					'/debitNotes',
					'/creditNotes',
					'/goodsReceivedNotes',
					'/investorProcessingProtocols',
					'/incomingLetters',
					'/commonTasks',
					// invoices
					'/costInvoices',
					'/materialInvoices',
					'/contractAdvanceInvoices',
					'/contractProformaInvoices',
					'/contractSalesInvoices',
					'/salesInvoices',
					'/customCostInvoices',
					'/correctionInvoices',
				]), //array passing in the function should include all childItems linkPaths
				childItems: [
					{
						id: 'demands',
						icon: <DemandIcon sx={sxStyles.icon} />,
						text: t('Demands'),
						linkPath: '/demands',
						activationRoutes: [{ path: '/demand/*' }, { path: '/demands' }],
						visible: isPathAllowed('/demands'),
					},
					{
						id: 'offers',
						icon: <OfferIcon sx={sxStyles.icon} />,
						text: t('Offers'),
						linkPath: '/offers',
						activationRoutes: [{ path: '/offer/*' }, { path: '/offers' }],
						visible: isPathAllowed('/offers'),
					},
					{
						id: 'contracts',
						icon: <ContractIcon sx={sxStyles.icon} />,
						text: t('Contracts'),
						linkPath: '/contracts',
						activationRoutes: [{ path: '/contract/*' }, { path: '/contracts' }],
						visible: isPathAllowed('/contracts'),
					},
					{
						id: 'orders',
						icon: <OrderIcon sx={sxStyles.icon} />,
						text: t('Orders'),
						linkPath: '/orders',
						activationRoutes: [{ path: '/order/*' }, { path: '/orders' }],
						visible: isPathAllowed('/orders'),
					},
					{
						id: 'processingProtocols',
						icon: <ProcessingProtocolIcon sx={sxStyles.icon} />,
						text: t('ProcessingProtocols'),
						linkPath: '/processingProtocols',
						activationRoutes: [{ path: '/processingProtocol/*' }, { path: '/processingProtocols' }],
						visible: isPathAllowed('/processingProtocols'),
					},
					{
						id: 'debitNotes',
						icon: <DebitNoteIcon sx={sxStyles.icon} />,
						text: t('DebitNotes'),
						linkPath: '/debitNotes',
						activationRoutes: [{ path: '/debitNote/*' }, { path: '/debitNotes' }],
						visible: isPathAllowed('/debitNotes'),
					},
					{
						id: 'creditNotes',
						icon: <SalesInvoiceIcon sx={sxStyles.icon} />,
						text: t('CreditNotes'),
						linkPath: '/creditNotes',
						activationRoutes: [{ path: '/creditNote/*' }, { path: '/creditNotes' }],
						visible: isPathAllowed('/creditNotes'),
					},
					{
						id: 'goodsReceivedNotes',
						icon: <GoodsReceivedNoteIcon sx={sxStyles.icon} />,
						text: t('GoodsReceivedNotes'),
						linkPath: '/goodsReceivedNotes',
						activationRoutes: [{ path: '/goodsReceivedNote/*' }, { path: '/goodsReceivedNotes' }],
						visible: isPathAllowed('/goodsReceivedNotes'),
					},
					{
						id: 'investorProcessingProtocols',
						icon: <InvestorProcessingProtocolIcon sx={sxStyles.icon} />,
						text: t('InvestorProcessingProtocols'),
						linkPath: '/investorProcessingProtocols',
						activationRoutes: [{ path: '/investorProcessingProtocol/*' }, { path: '/investorProcessingProtocols' }],
						visible: isPathAllowed('/investorProcessingProtocols'),
					},
					{
						id: 'IncomingLetters',
						icon: <MailOutlineIcon sx={sxStyles.icon} />,
						text: t('IncomingLetters'),
						linkPath: '/incomingLetters',
						activationRoutes: [{ path: '/incomingLetter/*' }, { path: '/incomingLetters' }],
						visible: isPathAllowed('/incomingLetters'),
					},
					{
						id: 'CommonTasks',
						icon: <TaskAltOutlinedIcon sx={sxStyles.icon} />,
						text: t('CommonTasks'),
						linkPath: '/commonTasks',
						activationRoutes: [{ path: '/commonTask/*' }, { path: '/commonTasks' }],
						visible: isPathAllowed('/commonTasks'),
					},
					{
						id: 'invoices',
						icon: <CostInvoiceIcon sx={sxStyles.icon} />,
						text: t('Invoices'),
						linkPath: '/contractProformaInvoices',
						visible: isAnyPathFromGroupAllowed([
							'/costInvoices',
							'/materialInvoices',
							'/contractAdvanceInvoices',
							'/contractProformaInvoices',
							'/contractSalesInvoices',
							'/salesInvoices',
							'/customCostInvoices',
							'/correctionInvoices',
						]), //array passing in the function should include all childItems linkPaths
						childItems: [
							{
								id: 'costInvoices',
								icon: <CostInvoiceIcon sx={sxStyles.icon} />,
								text: t('CostInvoices'),
								linkPath: '/costInvoices',
								activationRoutes: [{ path: '/costInvoice/*' }, { path: '/costInvoices' }],
								visible: isPathAllowed('/costInvoices'),
							},
							{
								id: 'materialInvoices',
								icon: <ListAltIcon />,
								text: t('MaterialInvoices'),
								linkPath: '/materialInvoices',
								activationRoutes: [{ path: '/materialInvoice/*' }, { path: '/materialInvoices' }],
								visible: isPathAllowed('/materialInvoices'),
							},
							{
								id: 'correctionInvoices',
								icon: <ListAltIcon />,
								text: t('CorrectionInvoices'),
								linkPath: '/correctionInvoices',
								activationRoutes: [{ path: '/correctionInvoice/*' }, { path: '/correctionInvoices' }],
								visible: isPathAllowed('/correctionInvoices'),
							},
							{
								id: 'customCostInvoices',
								icon: <ListAltIcon />,
								text: t('CustomCostInvoices'),
								linkPath: '/customCostInvoices',
								activationRoutes: [{ path: '/customCostInvoice/*' }, { path: '/customCostInvoices' }],
								visible: isPathAllowed('/customCostInvoices'),
							},
							{
								id: 'advanceInvoices',
								icon: <ListAltIcon />,
								text: t('AdvanceInvoices'),
								linkPath: '/contractAdvanceInvoices',
								activationRoutes: [{ path: '/contractAdvanceInvoice/*' }, { path: '/contractAdvanceInvoices' }],
								visible: isPathAllowed('/contractAdvanceInvoices'),
							},
							{
								id: 'proformaInvoices',
								icon: <ListAltIcon />,
								text: t('ProformaInvoices'),
								linkPath: '/contractProformaInvoices',
								activationRoutes: [{ path: '/contractProformaInvoice/*' }, { path: '/contractProformaInvoices' }],
								visible: isPathAllowed('/contractProformaInvoices'),
							},
							{
								id: 'contractSalesInvoices',
								icon: <ListAltIcon />,
								text: t('SalesInvoices'),
								linkPath: '/contractSalesInvoices',
								activationRoutes: [{ path: '/contractSalesInvoice/*' }, { path: '/contractSalesInvoices' }],
								visible: isPathAllowed('/contractSalesInvoices'),
							},
							{
								id: 'investorSalesInvoices',
								icon: <ListAltIcon />,
								text: t('InvestorSalesInvoices'),
								linkPath: '/salesInvoices',
								activationRoutes: [{ path: '/salesInvoice/*' }, { path: '/salesInvoices' }],
								visible: isPathAllowed('/salesInvoices'),
							},
						],
					},
				],
			},
			{
				id: 'premises',
				icon: <ApartmentIcon sx={sxStyles.icon} />,
				text: t('Premises'),
				linkPath: '/premises',
				activationRoutes: [{ path: '/premises/*' }],
				visible: isPathAllowed('/premises'),
			},
			{
				id: 'contractors',
				icon: <EngineeringIcon sx={sxStyles.icon} />,
				text: t('Contractors'),
				linkPath: '/dictionaries/contractors',
				activationRoutes: [{ path: '/dictionaries/contractors' }],
				visible: isPathAllowed('/dictionaries/contractors'),
			},
			{
				id: 'dictionaries',
				icon: <AutoStoriesIcon sx={sxStyles.icon} />,
				text: t('Dictionaries'),
				visible: isAnyPathFromGroupAllowed([
					'/dictionaries/measurement',
					'/dictionaries/vatRate',
					'/dictionaries/industries',
					'/dictionaries/currency',
					'/dictionaries/addresses',
					'/dictionaries/paymentMethod',
					'/dictionaries/masterCategory',
					'/dictionaries/masterRegistry',
					'/dictionaries/masterDocumentNumerator',
					// Premises
					'/dictionaries/premisesStatus',
					'/dictionaries/premisesHandoverStatus',
					'/dictionaries/premisesType',
					'/dictionaries/premisesPartType',
					'/dictionaries/premisesContactInfoType',
				]), //array passing in the function should include all childItems linkPaths
				childItems: [
					{
						id: 'premisesDictionaries',
						icon: <ApartmentIcon sx={sxStyles.icon} />,
						text: t('Premises'),
						linkPath: '',
						visible: isAnyPathFromGroupAllowed([
							'/dictionaries/premisesStatus',
							'/dictionaries/premisesHandoverStatus',
							'/dictionaries/premisesType',
							'/dictionaries/premisesPartType',
							'/dictionaries/premisesContactInfoType',
						]), //array passing in the function should include all childItems linkPaths
						childItems: [
							{
								id: 'premisesStatus',
								icon: <ApartmentIcon sx={sxStyles.icon} />,
								text: t('PremisesStatus'),
								linkPath: '/dictionaries/premisesStatus',
								activationRoutes: [{ path: '/dictionaries/premisesStatus' }],
								visible: isPathAllowed('/dictionaries/premisesStatus'),
							},
							{
								id: 'premisesHandoverStatus',
								icon: <ApartmentIcon sx={sxStyles.icon} />,
								text: t('PremisesHandoverStatus'),
								linkPath: '/dictionaries/premisesHandoverStatus',
								activationRoutes: [{ path: '/dictionaries/premisesHandoverStatus' }],
								visible: isPathAllowed('/dictionaries/premisesHandoverStatus'),
							},
							{
								id: 'premisesType',
								icon: <ApartmentIcon sx={sxStyles.icon} />,
								text: t('PremisesType'),
								linkPath: '/dictionaries/premisesType',
								activationRoutes: [{ path: '/dictionaries/premisesType' }],
								visible: isPathAllowed('/dictionaries/premisesType'),
							},
							{
								id: 'premisesPartType',
								icon: <ApartmentIcon sx={sxStyles.icon} />,
								text: t('PremisesPartType'),
								linkPath: '/dictionaries/premisesPartType',
								activationRoutes: [{ path: '/dictionaries/premisesPartType' }],
								visible: isPathAllowed('/dictionaries/premisesPartType'),
							},
							{
								id: 'premisesContactInfoType',
								icon: <ApartmentIcon sx={sxStyles.icon} />,
								text: t('PremisesContactInfoType'),
								linkPath: '/dictionaries/premisesContactInfoType',
								activationRoutes: [{ path: '/dictionaries/premisesContactInfoType' }],
								visible: isPathAllowed('/dictionaries/premisesContactInfoType'),
							},
						],
					},
					{
						id: 'measurement',
						icon: <StraightenIcon sx={sxStyles.icon} />,
						text: t('MeasurementUnits'),
						linkPath: '/dictionaries/measurement',
						activationRoutes: [{ path: '/dictionaries/measurement' }],
						visible: isPathAllowed('/dictionaries/measurement'),
					},
					{
						id: 'vatRate',
						icon: <PercentIcon sx={sxStyles.icon} />,
						text: t('VATRate'),
						linkPath: '/dictionaries/vatRate',
						activationRoutes: [{ path: '/dictionaries/vatRate' }],
						visible: isPathAllowed('/dictionaries/vatRate'),
					},
					{
						id: 'industries',
						icon: <HomeRepairServiceIcon sx={sxStyles.icon} />,
						text: t('Industries'),
						linkPath: '/dictionaries/industries',
						activationRoutes: [{ path: '/dictionaries/industries' }],
						visible: isPathAllowed('/dictionaries/industries'),
					},
					{
						id: 'currency',
						icon: <CurrencyExchangeIcon sx={sxStyles.icon} />,
						text: t('Currency'),
						linkPath: '/dictionaries/currency',
						activationRoutes: [{ path: '/dictionaries/currency' }],
						visible: isPathAllowed('/dictionaries/currency'),
					},
					{
						id: 'addresses',
						icon: <BusinessIcon sx={sxStyles.icon} />,
						text: t('Addresses'),
						linkPath: '/dictionaries/addresses',
						activationRoutes: [{ path: '/dictionaries/addresses' }],
						visible: isPathAllowed('/dictionaries/addresses'),
					},
					{
						id: 'paymentMethod',
						icon: <CreditCardIcon sx={sxStyles.icon} />,
						text: t('PaymentMethod'),
						linkPath: '/dictionaries/paymentMethod',
						activationRoutes: [{ path: '/dictionaries/paymentMethod' }],
						visible: isPathAllowed('/dictionaries/paymentMethod'),
					},
					{
						id: 'masterCategory',
						icon: <CategoryIcon sx={sxStyles.icon} />,
						text: t('MasterCategory'),
						linkPath: '/dictionaries/masterCategory',
						activationRoutes: [{ path: '/dictionaries/masterCategory' }],
						visible: isPathAllowed('/dictionaries/masterCategory'),
					},
					{
						id: 'masterRegistry',
						icon: <AppRegistrationIcon sx={sxStyles.icon} />,
						text: t('MasterRegistry'),
						linkPath: '/dictionaries/masterRegistry',
						activationRoutes: [{ path: '/dictionaries/masterRegistry' }],
						visible: isPathAllowed('/dictionaries/masterRegistry'),
					},
					{
						id: 'masterDocumentNumerator',
						icon: <PinOutlinedIcon sx={sxStyles.icon} />,
						text: t('MasterDocumentNumerator'),
						linkPath: '/dictionaries/masterDocumentNumerator',
						activationRoutes: [{ path: '/dictionaries/masterDocumentNumerator' }],
						visible: isPathAllowed('/dictionaries/masterDocumentNumerator'),
					},
				],
			},
			{
				id: 'synchronization',
				icon: <CloudSyncIcon sx={sxStyles.icon} />,
				text: t('Synchronization'),
				linkPath: '/connector',
				activationRoutes: [{ path: '/connector' }],
				visible: isPathAllowed('/connector'),
			},
		],
		[t, isPathAllowed]
	)

	const navigationItems = allNavigationItems.map(item => {
		if (item.childItems) {
			// Sort the childItems alphabetically by the 'text' property
			const sortedChildItems = [...item.childItems].sort((a, b) => a.text.localeCompare(b.text))
			return {
				...item,
				childItems: sortedChildItems,
			}
		}
		return item
	})

	return { navigationItems }
}

export default useNavigationItems
