import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SyncIcon from '@mui/icons-material/Sync'
import Grid from '@mui/material/Unstable_Grid2'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { AutocompleteInput, SettingsMenu, TooltipButton } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { IPremisesAddress, IProjectModel } from 'models'
import { usePremisesAddressService, useProjectsListService } from 'services'
import FormatAddress from 'utils/FormatAdress'

import AddPremisesMenu from './AddPremisesMenu'
import AddressDialog, { AddressAction } from './AddressDialog'
import { SelectValues } from './PremisesList'
import {
	CONTEXT_STATE_NAME,
	INITIALSTATE,
	SELECTED_ADDRESS_SESSION_STORAGE_NAME,
	SELECTED_PROJECT_SESSION_STORAGE_NAME,
} from './initial-state'

type Props = {
	apiRef: { current: any }
	getData: () => void
	selectedProject: SelectValues
	setSelectedProject: (value: SelectValues) => void
	selectedAddress: SelectValues
	setSelectedAddress: (value: SelectValues) => void
	isAddressHasPremises: boolean
	readOnly: boolean
	projectId?: string
}

type ListOfAddresses = {
	Id: string
	Name: string
}

const Toolbar = ({
	apiRef,
	getData,
	selectedProject,
	setSelectedProject,
	selectedAddress,
	setSelectedAddress,
	isAddressHasPremises,
	readOnly,
	projectId,
}: Props) => {
	const [listOfProjects, setListOfProjects] = useState<IProjectModel[]>([])
	const [listOfAddresses, setListOfAddresses] = useState<ListOfAddresses[]>([])
	const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false)
	const [addressAction, setAddressAction] = useState<AddressAction>('add')

	const { t } = useTranslation('premises')

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()

	const { getListOfProjects } = useProjectsListService()
	const { getPremisesAddresses } = usePremisesAddressService()

	const handleProjectChange = (e: any) => {
		const newProject = { id: e.value, name: e.label }

		setSelectedProject(newProject)
		sessionStorage.setItem(SELECTED_PROJECT_SESSION_STORAGE_NAME, JSON.stringify(newProject))
		setSelectedAddress({ id: '', name: '' })
	}

	const handleAddressChange = (e: any) => {
		const newAddress = { id: e.value, name: e.label, projectId: selectedProject.id }

		setSelectedAddress(newAddress)
		sessionStorage.setItem(SELECTED_ADDRESS_SESSION_STORAGE_NAME, JSON.stringify(newAddress))
	}

	const handleOpenAddressDialog = (action: AddressAction) => {
		setIsAddressDialogOpen(true)
		setAddressAction(action)
	}

	const getProjectsData = async (instanceId: string) => {
		if (listOfProjects.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getListOfProjects()

				setListOfProjects(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	const getAddressesData = async (instanceId: string, projectId: string = selectedProject.id) => {
		try {
			updateAutocompleteInputLoading({ instanceId, loading: true })
			const response = await getPremisesAddresses(projectId)

			const formatedAddress = response.map((el: IPremisesAddress) => ({
				Id: el.Id,
				Name: FormatAddress(el),
				ProjectId: el.ProjectId,
			}))

			setListOfAddresses(formatedAddress)
		} catch (err) {
			console.error(err)
		} finally {
			updateAutocompleteInputLoading({ instanceId, loading: false })
		}
	}

	useEffect(() => {
		const project = sessionStorage.getItem(SELECTED_PROJECT_SESSION_STORAGE_NAME)
		const savedProject = project ? JSON.parse(project) : null

		if (savedProject) {
			setSelectedProject(savedProject)
		} else {
			return
		}

		const address = sessionStorage.getItem(SELECTED_ADDRESS_SESSION_STORAGE_NAME)

		if (address) {
			const savedAddress = JSON.parse(address) as SelectValues
			setSelectedAddress(savedAddress.projectId === savedProject.id ? savedAddress : { id: '', name: '' })
		}
	}, [])

	return (
		<GridToolbarContainer className="items-center justify-between m-2">
			<Grid className="w-2/3 flex items-center" container rowSpacing={3} columnSpacing={2}>
				{!projectId && (
					<>
						<AutocompleteInput
							dimensions={{ xs: 4, sm: 4, md: 4, lg: 4 }}
							initialValue={{
								value: selectedProject.id || '',
								label: selectedProject.name || '',
							}}
							onOpen={() => getProjectsData('Fields.ProjectId')}
							onChange={handleProjectChange}
							options={listOfProjects}
							readOnly={false}
							instanceId={'Fields.ProjectId'}
							inputLabel={t('Fields.ProjectId')}
							name="ProjectId"
							forProject
							labelFieldName="InvestmentName"
							hintTranslationPath="Premises.ProjectId"
							otherProps={{ size: 'small' }}
						/>
						{selectedProject.id && (
							<>
								<AutocompleteInput
									dimensions={{ xs: 4, sm: 4, md: 4, lg: 4 }}
									initialValue={{
										value: selectedAddress.id || '',
										label: selectedAddress.name || '',
									}}
									onOpen={() => getAddressesData('Fields.AddressId')}
									onChange={handleAddressChange}
									options={listOfAddresses}
									readOnly={false}
									instanceId={'Fields.AddressId'}
									inputLabel={t('Fields.AddressId')}
									name="AddressId"
									labelFieldName="Name"
									hintTranslationPath="Premises.AddressId"
									otherProps={{ size: 'small' }}
								/>
								{!readOnly && (
									<TooltipButton
										title="Addresses.AddAddressDialog.Title"
										translationFile="dictionaries"
										onClick={() => handleOpenAddressDialog('add')}
										IconComponent={AddIcon}
										fontSize="medium"
									/>
								)}
								{selectedAddress.id && !readOnly && (
									<>
										<TooltipButton
											className="p-1.5"
											title="Other.UpdateAddress"
											translationFile="premises"
											onClick={() => handleOpenAddressDialog('edit')}
											IconComponent={EditIcon}
											fontSize="small"
										/>
										<TooltipButton
											className="p-1.5"
											title={isAddressHasPremises ? 'Other.CannotDeleteAddressWithPremises' : 'Other.DeleteAddress'}
											translationFile="premises"
											onClick={() => handleOpenAddressDialog('delete')}
											IconComponent={DeleteIcon}
											disabled={isAddressHasPremises}
											fontSize="small"
										/>
									</>
								)}
							</>
						)}
					</>
				)}
			</Grid>
			<div>
				<TooltipButton title="general.Refresh" onClick={getData} IconComponent={SyncIcon} fontSize="medium" />
				<SettingsMenu apiRef={apiRef} initialState={INITIALSTATE} contextName={CONTEXT_STATE_NAME} />
				{readOnly || projectId ? null : (
					<AddPremisesMenu
						address={selectedAddress}
						updateList={getData}
						disabled={!selectedProject.id || !selectedAddress.id}
					/>
				)}
			</div>
			{isAddressDialogOpen && (
				<AddressDialog
					isOpen={isAddressDialogOpen}
					setIsOpen={setIsAddressDialogOpen}
					projectId={selectedProject.id}
					updateAddressesList={getAddressesData}
					setSelectedAddress={setSelectedAddress}
					action={addressAction}
					selectedAddress={selectedAddress}
				/>
			)}
		</GridToolbarContainer>
	)
}

export default Toolbar
