import { useTranslation } from 'react-i18next'

import { Autocomplete, CircularProgress, InputAdornment, TextField, createFilterOptions } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useAppSelector } from 'features'

import { InputHint } from '../buttons'
import { prepareAutocompleteInputOptions } from './prepareAutocompleteInputOptions'

export type AutocompleteInputOption = { value: string; label: string; SearchItems?: string }

type Props = {
	dimensions?: { xs?: number; sm?: number; md?: number; lg?: number }
	order?: { xs?: number; sm?: number; md?: number; lg?: number }
	initialValue: AutocompleteInputOption
	onOpen: () => void
	onChange: (newValue: any, valueFieldName: string, labelFieldName: string) => void
	options: any[]
	readOnly: boolean
	inputLabel: string
	optionValueFieldName?: string
	optionLabelFieldName?: string
	name: string
	labelFieldName: string
	instanceId: string
	forProject?: boolean
	endAdorment?: React.ReactNode
	hintTranslationPath?: string
	otherProps?: any
}

type OptionsType = {
	value: string
	label: string
	SearchItems: string
}

const AutocompleteInput = ({
	dimensions = { xs: 12, sm: 12, md: 12, lg: 12 },
	order = undefined,
	initialValue,
	onOpen,
	onChange,
	options,
	readOnly,
	inputLabel,
	optionValueFieldName = 'Id',
	optionLabelFieldName = 'Name',
	name,
	labelFieldName,
	instanceId,
	forProject = false,
	endAdorment = null,
	hintTranslationPath,
	otherProps,
}: Props) => {
	const { xs, sm, md, lg } = dimensions

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { t } = useTranslation()

	const handleInputChange = (e: any, newValue: any) => {
		if (newValue?.value && newValue?.label) onChange(newValue, name, labelFieldName)
	}

	const optionsData = prepareAutocompleteInputOptions(options, optionValueFieldName, optionLabelFieldName, forProject)

	return (
		<Grid xs={xs} sm={sm} md={md} lg={lg} order={order}>
			<Autocomplete
				clearOnBlur
				disablePortal
				clearOnEscape
				autoHighlight
				value={initialValue}
				onOpen={onOpen}
				onChange={handleInputChange}
				options={optionsData}
				filterOptions={createFilterOptions({
					stringify: (option: OptionsType) => (option.SearchItems ? option.SearchItems.toString() : ''),
				})}
				loading={autocompleteInputLoading[instanceId]}
				loadingText={t('general.LoadingText', { ns: 'translation' })}
				noOptionsText={t('general.NoData', { ns: 'translation' })}
				disabled={readOnly}
				renderOption={(props, option: OptionsType) => (
					<li {...props} key={option.value}>
						{option.label}
					</li>
				)}
				isOptionEqualToValue={(option, value) => option.value === value.value && option.label === value.label}
				renderInput={(params: any) => (
					<TextField
						{...params}
						label={inputLabel}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{autocompleteInputLoading[instanceId] ? (
										<CircularProgress size={20} sx={{ mr: '5px' }} />
									) : (
										endAdorment
									)}
									{params.InputProps.endAdornment}
									{hintTranslationPath && (
										<InputAdornment position="end">
											<InputHint translationPath={hintTranslationPath} />
										</InputAdornment>
									)}
								</>
							),
						}}
					/>
				)}
				{...otherProps}
			/>
		</Grid>
	)
}

export default AutocompleteInput
