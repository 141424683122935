import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	aggregation: {
		model: { Value: 'sum', ValueOfParts: 'sum', BudgetValue: 'budgetValue', ValueOfAnnex: 'sum' },
	},
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'__tree_data_group__',
			'Name',
			'CostType',
			'MeasurementUnitId',
			'Amount',
			'Price',
			'Value',
			'ValueOfParts',
			'BudgetValue',
			'ValueOfAnnex',
			'SecurityDepositAmountNWK',
			'SecurityDepositAmountUWiU',
			'SecurityDepositInvoiceRetentionValueNWK',
			'SecurityDepositInvoiceRetentionValueUWiU',
			'SecurityDepositInvoiceRetentionValue',
			'Parameters',
			'BudgetEntryId',
			'actions',
		],
		dimensions: {
			__tree_data_group__: { maxWidth: -1, minWidth: 130, width: 300, flex: 0 },
			Name: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			CostType: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			MeasurementUnitId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Price: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Value: {
				maxWidth: -1,
				minWidth: 50,
				width: 300,
			},
			ValueOfParts: {
				maxWidth: -1,
				minWidth: 50,
				width: 300,
			},
			BudgetValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 300,
			},
			ValueOfAnnex: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			SecurityDepositAmountNWK: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			SecurityDepositAmountUWiU: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			SecurityDepositInvoiceRetentionValueNWK: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			SecurityDepositInvoiceRetentionValueUWiU: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			SecurityDepositInvoiceRetentionValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			Parameters: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			BudgetEntryId: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 130,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'contractEntriesDataGridState'
